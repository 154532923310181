/* TODO: replace with Adrenaline fonts when available */
@font-face {
  font-family: 'DMSans-Regular';
  src: url(https://cdn.experienceoz.com.au/resources/fonts/DMSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'DMSans-Medium';
  src: url(https://cdn.experienceoz.com.au/resources/fonts/DMSans-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'DMSans-SemiBold';
  src: url(https://cdn.experienceoz.com.au/resources/fonts/DMSans-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'DMSans-Bold';
  src: url(https://cdn.experienceoz.com.au/resources/fonts/DMSans-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Din-Cond';
  src: url(https://cdn.experienceoz.com.au/resources/fonts/DINCond.woff2) format('woff2');
}

@font-face {
  font-family: 'Monologue';
  src: url(https://cdn.experienceoz.com.au/resources/fonts/Monologue.woff2) format('woff2');
}

:root {
  --font-regular: 'DMSans-Regular', Arial, Sans Serif;
  --font-medium: 'DMSans-Medium', Arial, Sans Serif;
  --font-semibold: 'DMSans-Medium', Arial, Sans Serif;
  --font-bold: 'DMSans-Bold', Arial, Sans Serif;
  --font-heading: 'Din-Cond', Arial, Sans Serif;
  --font-fancy: 'Monologue', Arial, Sans Serif;

  --brand-primary: #70edff;
  --brand-primary-light: #96f1ff;
  --brand-primary-dark: #70edff;
  --brand-secondary: #1a1a1a;
  --brand-secondary-light: #323232;
  --brand-secondary-dark: black;
  --brand-accent: #ff6f1c;
  --brand-accent-dark: #e76012;
  --brand-accent-secondary: #a6a8ab;
  --brand-accent-secondary-dark: #8c8f93;

  --text-primary: #000000;
  --text-inverse: #ffffff;
  --text-primary-light: #58575b;
  --text-disabled: #bababa;

  --grey-100: #a6a8ab;
  --grey-50: #D3D4D5;
  --grey-25: #E9E9EA;
  --grey-10: #F6F6F7;

  --sale: #EB4550;

  --bg-black: #1a1a1a;
  --bg-white: #ffffff;
  --bg-disabled: #e4e4e4;
  --bg-primary-grey: #f2f1f3;

  --ui-red: #b82626;
  --ui-orange: #e68320;
  --ui-green: #68b30a;
  --ui-blue: #2a93d5;

  --cubicBezier: cubic-bezier(0.2, 1.07, 0.54, 1.37);
  --cubicBezierImage: cubic-bezier(0.18, 0.77, 0.57, 1.19);
}

body {
  font-family: var(--font-regular);
  color: var(--text-primary);
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

body.noscroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

strong,
b {
  font-family: var(--font-bold);
}

em {
  font-style: italic;
}

.embeddedServiceHelpButton .helpButton {
  height: 40px !important;
}
.embeddedServiceHelpButton .helpButton .uiButton {
  height: 40px !important;
}